@use '../node_modules/bootstrap/scss/bootstrap.scss';
@use './styles/variables' as *;

.wordmark {
  height: 65px;
}

nav {
  max-width: 400px;
  height: 40px;
}

.nav-link {
  color: $nav-link-color-default;

  &:hover {
    color: $nav-link-color-default-hover;
  }

  &.nav-link-active {
    color: $nav-link-color-active;
    background-color: $nav-link-background-color-active;

    &:hover {
      color: $nav-link-color-active-hover-piece;
    }

    &:hover.gallery {
      color: $nav-link-color-active-hover-gallery;
    }
  }
}

.home-caption {
  max-width: 600px;
}

.gallery-images-container {
  max-width: $bootstrap-breakpoint-sm;
}

.gallery-image {
  width: $gallery-detail-dimension;
  height: $gallery-detail-dimension;
  background-color: $thirty-five-percent-gray;
  border: 1px solid $gallery-detail-color-border;
  outline: 3px solid transparent;

  &:hover {
    border-color: $gallery-detail-color-border-hover;
    outline-color: $gallery-detail-color-border-hover;
  }
}

.piece-with-border,
.noney-front-animation,
.noney-sheet {
  @include image-border;
}

.max-width-lg-breakpoint {
  max-width: $bootstrap-breakpoint-lg;
}

.max-width-xxl-breakpoint {
  max-width: $bootstrap-breakpoint-xxl;
}

img.self-portrait {
  margin-right: 1rem;
  float: left;
}

.epoch-travelers-image,
.sunshine-sunshine-image {
  width: 300px;
}

.noney-rule {
  width: 150px;
  border-bottom: 1px solid $noney-violet;
}

.noney-image {
  max-width: 340px;
}

/* Breakpoints */

@media only screen and (min-width: $bootstrap-breakpoint-sm) {

  .epoch-travelers-image,
  .sunshine-sunshine-image {
    width: initial;
  }

  .noney-image {
    max-width: initial;
  }
}
