/* Color Variables */

$black: #000;
$fifty-percent-gray: #7f7f7f;
$thirty-five-percent-gray: #a6a6a6;

$dark-cyan-blue: #0d6efd;
$dark-hotpink: #e70475;
$dark-yellow-green: #c0de4b;
$html-hotpink: #ff69b4;
$medium-dark-pink: #a05;

$noney-violet: #330066;

/* Color Assignments */

$nav-link-color-default: $dark-cyan-blue;
$nav-link-color-default-hover: $dark-hotpink;
$nav-link-color-active: $black;
$nav-link-color-active-hover-gallery: $black;
$nav-link-color-active-hover-piece: $medium-dark-pink;
$nav-link-background-color-active: $dark-yellow-green;

$gallery-detail-color-border: $fifty-percent-gray;
$gallery-detail-color-border-hover: $html-hotpink;

$piece-border-color: $thirty-five-percent-gray;

/* Size */
$gallery-detail-dimension: 80px;

/* Border */
@mixin image-border {
  border: 1px solid $piece-border-color;
}

/* Bootstrap Breakpoints */

$bootstrap-breakpoint-sm: 576px;
$bootstrap-breakpoint-md: 768px;
$bootstrap-breakpoint-lg: 992px;
$bootstrap-breakpoint-xl: 1200px;
$bootstrap-breakpoint-xxl: 1400px;
